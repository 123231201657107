import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { matchPath, useNavigate } from "react-router-dom";
import styles from "./index.module.less";
import { ReactComponent as LogoSvg } from "./images/logo.svg";
import { ReactComponent as HomeSvg } from "./images/home.svg";
import { ReactComponent as InscribeSvg } from "./images/inscribe.svg";
import { ReactComponent as TwitterSvg } from "../Footer/images/twitter.svg";
import { ReactComponent as BridgeSvg } from "./images/bridge.svg";
import { ReactComponent as Disconnectsvg } from "./images/Disconnect.svg";
import { ReactComponent as Voyagavg } from "./images/Voyaga.svg";
import classNames from "classnames";
import { Dropdown, Spin, Toast } from "@douyinfe/semi-ui";
import walletInfo from "../../store/walletInfo";
import { observer } from "mobx-react-lite";
import useTwitterLogin from "../../useHooks/useTwitterLogin";
import { MyContext } from "../../App";
import { useFormat } from "../../locale";
import { ExportOutlined } from "@ant-design/icons";
import RankingSvg from "./images/Ranking.svg";
import ExchangeSvg from "./images/Exchange.svg";
import StakingSvg from "./images/Staking.svg";
import RewardSvg from "./images/Reward.svg";
import DocSvg from "./images/doc.svg";
import giftPng from "../../pages/RewardDashboard/images/gift.gif";
import {
  BridgePath,
  HomePath,
  InscribePath,
  RankingPath,
  BenefitsPath,
  accountPath,
  SwapPath,
  rewardDashboardPath,
  SubdomainsLIst,
  ShareLabelPath,
} from "../../routes";
import { onAddressAbbreviation } from "../../utils";
import ConnectWalletModal from "../../component/ConnectWallet";
const Header = () => {
  const navigate = useNavigate();
  const { twitterLogin, loading: loginLoading } = useTwitterLogin();
  const { userInfo, setUserInfoState } = useContext(MyContext);
  const { format } = useFormat();
  const [modalVisible, setModalVisible] = useState(false);
  const [logOutLoading, setLogOutLoading] = useState<boolean>(false);
  const MenuList = [
    {
      name: "Home",
      path: HomePath,
      png: <HomeSvg />,
    },
    {
      name: "Voyage",
      path: "",
      png: <Voyagavg />,
    },
    {
      name: "Bridge",
      path: BridgePath,
      png: <BridgeSvg />,
    },
    {
      name: "Pre-sale",
      path: InscribePath,
      png: <InscribeSvg />,
    },
  ];
  const disconnect = () => {
    walletInfo.setAccount("");
  };
  const onTwitterLogin = () => {
    twitterLogin(() => {});
  };

  const logoutBtn = async () => {
    //最外层会监听到退出到登录页面
    try {
      setLogOutLoading(true);
      localStorage.removeItem("authToken");
      setUserInfoState({});
    } catch (e) {
      setLogOutLoading(false);
    } finally {
      setTimeout(() => {
        setLogOutLoading(false);
      }, 1000);
    }
  };
  const childrenOpen = (url) => {
    window.open(`${window.location.origin}${url}`);
  };
  const isSubdomains = useMemo(() => {
    if (window.location.pathname.search(ShareLabelPath) === 0) {
      return true;
    } else {
      return SubdomainsLIst.includes(window.location.pathname);
    }
  }, []);

  const onLaunch = () => {
    if (document.querySelector(".chrome-extension-flag-div")) return;
    window.open(
      "https://chrome.google.com/webstore/detail/sumup-twitter-suitcase/fgflndiedodffhcdiopjphegdlofpgoc"
    );
  };
  return (
    <div
      className={classNames(styles.header, {
        [styles.childrenHeader]: matchPath(
          rewardDashboardPath,
          window.location.pathname
        ),
      })}
    >
      <div className={styles.top}>
        <div
          className={styles.left}
          onClick={() => {
            navigate("/");
          }}
        >
          <LogoSvg />
          <div className={styles.text}>S.A.R.V.I.S.</div>
        </div>
        <div className={styles.right}>
          {MenuList.map((item, index) => {
            if (item.name === "Voyage") {
              return (
                <Dropdown
                  key={index}
                  position="bottomLeft"
                  render={
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <div
                          className={styles.dropdownItem}
                          onClick={() => {
                            childrenOpen(RankingPath);
                          }}
                        >
                          <RankingSvg />
                          <div>{format("Ranking")}</div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <div
                          className={styles.dropdownItem}
                          onClick={() => {
                            // childrenOpen(SwapPath);
                            Toast.info(format("Coming soon"));
                          }}
                        >
                          <ExchangeSvg />
                          <div>{format("Swap")}</div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <div
                          className={styles.dropdownItem}
                          onClick={() => {
                            Toast.info(format("Coming soon"));
                          }}
                        >
                          <StakingSvg />
                          <div>{format("SRVS Staking")}</div>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <div
                          className={styles.dropdownItem}
                          onClick={() => {
                            childrenOpen(rewardDashboardPath);
                          }}
                        >
                          <RewardSvg />
                          <div>{format("Reward Dashboard")}</div>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  }
                >
                  <div
                    className={classNames(styles.item, {
                      [styles.active]: matchPath(
                        rewardDashboardPath,
                        window.location.pathname
                      ),
                    })}
                  >
                    <img src={giftPng} alt="" className={styles.lottie} />
                    <div> {item.name}</div>
                  </div>
                </Dropdown>
              );
            } else {
              return (
                <div
                  className={classNames(styles.item, {
                    [styles.active]: matchPath(
                      item.path,
                      window.location.pathname
                    ),
                  })}
                  onClick={() => {
                    if (SubdomainsLIst.includes(item.path)) {
                      childrenOpen(item.path);
                    } else {
                      navigate(item.path);
                    }
                  }}
                  key={index}
                >
                  {item.png}
                  <div> {item.name}</div>
                </div>
              );
            }
          })}
          <Dropdown
            position="bottomLeft"
            render={
              <Dropdown.Menu>
                <Dropdown.Item>
                  <div
                    className={styles.dropdownItem}
                    onClick={() => {
                      window.open(
                        "https://sarvis.gitbook.io/docs/intro/overview"
                      );
                    }}
                  >
                    <div>{format("About")}</div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item>
                  <div
                    className={styles.dropdownItem}
                    onClick={() => {
                      window.open("https://medium.com/@AiSarvis");
                    }}
                  >
                    <div>{format("Medium")}</div>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            }
          >
            <div className={classNames(styles.item)}>
              <DocSvg />
              <div> {format("Docs")}</div>
            </div>
          </Dropdown>
          {isSubdomains ? (
            <>
              <div style={{ minWidth: 120 }}>
                {localStorage.getItem("authToken") ? (
                  <Dropdown
                    position="bottomLeft"
                    render={
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <div
                            className={styles.dropdownItem}
                            onClick={logoutBtn}
                          >
                            {logOutLoading ? <Spin /> : ""}
                            <ExportOutlined />
                            <div>{format("Log out")}</div>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    }
                  >
                    <div className={styles.userBox}>
                      <TwitterSvg className={styles.twitterSvg} />
                      <div>{userInfo.twitterAccount}</div>
                    </div>
                  </Dropdown>
                ) : (
                  <button style={{ marginLeft: 0 }} onClick={onTwitterLogin}>
                    <TwitterSvg className={styles.twitterSvg} /> Sign up with
                    Twitter
                    {loginLoading ? <Spin /> : ""}
                  </button>
                )}
              </div>

              {walletInfo.getAccount() === "" ? (
                <button
                  onClick={() => {
                    setModalVisible(true);
                  }}
                >
                  Connect Wallet
                </button>
              ) : (
                <Dropdown
                  position="bottomLeft"
                  render={
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={disconnect}>
                        <div className={styles.dropdownItem}>
                          <Disconnectsvg />
                          <div>{format("Disconnect")}</div>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  }
                >
                  <button>
                    <div className={styles.walletBox}>
                      {walletInfo.isUnisat ? (
                        <img
                          src="https://next-cdn.unisat.io/_/19/logo/color.svg"
                          alt="logo of UniSat Wallet"
                        />
                      ) : (
                        <img
                          src="https://next-cdn.unisat.io/_/19/img/wallet/okx_wallet_icon.svg"
                          alt="logo of OKX Wallet"
                        />
                      )}
                      {onAddressAbbreviation(walletInfo.getAccount())}
                    </div>
                  </button>
                </Dropdown>
              )}
            </>
          ) : (
            <>
              <button
                id="PromotionCodeStatusBtn"
                onClick={onLaunch}
                className={styles.launchBtn}
              >
                {format("Launch")}
              </button>
            </>
          )}

          <ConnectWalletModal
            visible={modalVisible}
            onClose={() => setModalVisible(false)}
          />
        </div>
      </div>
    </div>
  );
};
export default observer(Header);
