const zhCN = {
    "再次操作": "操作失败，请稍后再试。",
    'Remaining Time': '剩余时间',
    "Exit the success": "退出成功",
    'Docs': '文档',
    'Download': '下载',
    'English': 'English',
    '热门榜单': '热门榜单',
    '标签创作榜': '标签创作榜',
    '标签传播榜': '标签传播榜',
    '影响力KOL榜': '影响力KOL榜',
    'Account': '传播者',
    'Introduction': '简介',
    'Followers': '粉丝数',
    'Share Count': '传播数量',
    'Share CountDes': '这个账号的总分享数量',
    'Total Clicks': '总传播',
    'Total ClicksDes': '这个账号分享的链接的总点击数',
    'Current Clicks': '本期传播',
    'Current ClicksDes': '本期这个账号分享的链接的总点击数',
    'Bonus Reward': '算力加成',
    'Bonus RewardDes': '当前额外的算力加成',
    "Follow": "关注",
    "Label": "标签",
    "Clicks": "传播数",
    'Share': '分享',
    'No data': '暂无数据',
    "Sign up with Twitter": "使用 Twitter 登录",
    "About": "关于",
    "Medium": "媒体",
    "Launch": "启动",
    //tab3
    'KOL': 'KOL',
    'KOL Introduction': '简介',
    'Tagged': '被标记数',
    'Influence': '影响力',

    "Follow label successfully": "关注标签成功",
    "Unfollow label successfully": "取消关注标签成功",
    'TaggedDes': '这个账号被标记了多少次',
    'InfluenceDes': '这些收录该kol的标签，关注人数的总和',

    //tab1
    '总关注': '总关注',
    'Creator': '创作者',
    'CreatorDes': '这个标签的创建者',
    'Members': '收录人数',
    'MembersDes': '这个标签标记了多少个账号',
    'Influence1': '影响力',
    'Influence1Des': '这个标签标记的账号的关注人数总和',
    'Increase': '本期新增',
    "IncreaseDes": "这个标签本期冲榜新增的关注人数",
    "Log out": "退出",
    'Following': '关注',
    'Followers1': '关注者',
    'Recent Activity': '最近活跃时间',
    'Account1': '账号',

    "cancelFollow": "取消关注",
    'FollowLabelDex': '关注后，您将可以在 Summafeeds 中跟踪与该标签相关的最新推文。',
    'ShareLabelDes': '点击传播优质社区标签，参与挖矿，共享奖励！',
    'CreationDes': '统计该标签当前冲榜期间的新增关注人数',
    "PropagationDes": '统计分享标签获得最多点击的用户',
    'KOLDes': '统计获得最多关注的被标签KOL',

    //modal
    "Followed successfully!": "关注成功！",
    'Check and share now': '立即查看并分享，',
    'participate in mining, and get rewards!': '参与挖矿，共享奖励！',
    'Mint Now': '参与挖矿',
    'Later': '稍后再说',

    //modal2
    'copyALl': '复制全部',
    'copyLink': '复制链接',
    'sharemodalSharing': '分享标签',
    'sharemodalDes1': '将该标签分享链接在浏览器打开,参与标签的传播挖矿',
    'Change It': '点击更换一个复制文案',
    'sharemodalText1': '社区新动向，等你来挖矿！',
    'sharemodalText2': "参与标签的传播挖矿，让更多用户了解和关注KOL的标记信息",
    'sharemodalText3': '现在就行动吧！',
    'sharemodalText4': '现在就开始复制并传播优质社区标签',
    'sharemodalText5': '参与挖矿，共享奖励！',
    'sharemodalText6': '探索社区热点！',
    'sharemodalText7': '与更多人分享标签，参与挖矿，分享奖励！',

    //share
    '成员': '成员',
    'Followed': '已关注',
    '粉丝': '粉丝',
    '正在关注': '正在关注',
    '最后更新时间': '最后更新时间',
    '推特用户': '推特用户',
    'earnSRVSCoin': '分享收益',
    'Search': '请输入搜索内容',

    'Disconnect': '断开连接',
    'Account Settings': '账号设置',
    "No more data": "没有更多数据了",
    'shwShareText': '还没有S.A.R.V.I.S.铭文， 立即获取， 或者加入社群获得核销码，体验更多Sumup功能！ ',
    'Get Now': '立即获取',
    'Join Now': '加入社群',
    'Loading': '加载中',
    'Ranking': '排名',
    'Exchange': '兑换',
    'SRVS Staking': 'SRVS Staking',
    'Reward Dashboard': '奖励面板',
    //rewardDashboard
    'rewardDes': 'Members Enjoy Additional Benefits and Rewards',
    "Creative Rewards": "Creative Rewards",
    'Share Rewards': 'Share Rewards',
    'Label to Earn': 'Label to Earn',
    'Labeled to Earn': 'Labeled to Earn',
    'Influence Rewards': 'Influence Rewards',
    'Rewards List': 'Rewards List',
    "Reward": "Reward",
    'Amount': 'Amount',
    'My Labels': 'My Labels',
    'My Spread': 'My Spread',
    'My Influence': 'My Influence',
    'Click': 'Click',
    'Copied to clipboard': 'Copied to clipboard',
    'Labels': 'Labels',
    'Pending Rewards': 'Pending Rewards',
    'step1Des': 'Please click this button below and tweet a verification message on Twitter,keep your tweets visible for at least three days.',
    'step2Des': 'On your tweet, find the share button. Copy link and paste it here. Click the button to verify your account.',
    'step2Error': '验证失败',
    'Claim': 'Claim',
    'Data': 'Data',
    'Claim successfully': 'Claim successfully',
    "Claim failed": "Claim failed",
    "Swap": "Swap",
    'Coming soon': 'Coming soon!',
    "public Labels": "公开标签",
    "Withdraw": "提取",
    "Balance": "余额",
    "Swap Rate": "兑换比例",
    "History": "历史记录",
    "MySwap": "我的兑换",
    //myHistory
    "Participants": "总参与人数",
    "Added": "总投入积分",
    "HistorySwap": '总兑换SRVS',
    "Rate": "比列",
    "Date": "时间",
    "swapErroAmount0": '请输入余额',
    "swapErroAmountMax": "余额不足",
    "Calculating": "Calculating",
    "Live": "Live",
    "WithdrawSuccess": "提取成功",
    "AddSuccess": "添加成功",
    "Next": "Next",
    "Start Later": 'Start Later',
    "swapAdd": 'Add',
    "swapWithdrawError": '超过可提取余额',
    "Confirm": "确认",
    "Cancel": "取消",



}
export default zhCN;