import {makeAutoObservable} from "mobx";

class WalletInfo {
    account = '';
    isUnisat = false;
    btcBalance = 0;

    constructor() {
        makeAutoObservable(this);
    }

    setAccount = (account) => {
        this.account = account;
    }

    getAccount = () => {
        return this.account;
    }

    setUnisatState = (state) => {
        this.isUnisat = state;
    }

    getUnisatState = () => {
        return this.isUnisat;
    }

    setBtcBalance = (balance) => {
        this.btcBalance = balance;
    }

    getBtcBalance = () => {
        return this.btcBalance;
    }
}

const walletInfo = new WalletInfo();
export default walletInfo;