import { request } from "./request";
import { AxiosRequestConfig } from "axios";

export async function getMessageByAddress(params: { address: string }) {
  return request.get("/user/getMessageByAddress", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: { ...params },
  });
}

export async function getRoundTime() {
  return request.get("/label-share/getRound", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

export async function getCreativeRank(params: { q: string }) {
  return request.get("/label-rank/creativeRank", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: { ...params },
  });
}

export async function getPopularityRank(q) {
  return request.get("/label-rank/popularityRank", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: { ...q },
  });
}

export async function getInfluenceRank(q) {
  return request.get("/label-rank/influenceRank", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: { ...q },
  });
}
export async function getInfluenceList(params: {
  twitterUserId: string;
  pageNum: number;
  pageSize: number;
}) {
  return request.get("/label-rank/influenceList", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: { ...params },
  });
}

export async function getLabelMemberInfo(params: {
  labelId: string;
  pageNum: number;
  pageSize: number;
}) {
  return request.get("/label-rank/getLabelMemberInfo", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: { ...params },
  });
}

export async function getSpreadCount(params: {
  userId: string;
  pageNum: number;
  pageSize: number;
}) {
  return request.get("/label-rank/getSpreadCount", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: { ...params },
  });
}

export interface UserCurretDataType {
  email: string;
  id: number;
  twitterAccount: string;
  userName: string;
  twitterUserName: string;
  vipComboState: number;
  maxWriteOffCodeNum: number;
  useWriteOffCodeNum: number;
  isBindTelegram: boolean;
  allowNum: number;
  address: string;
  amount: number;
  curPrice: number;
}
export async function userCurretnFetch() {
  return request.get("/user/current", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

export async function getShareLinkFetch(lableId: number) {
  return request.get(`/label-share/getShareLink/${lableId}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

export interface SuccessDataType {
  code: number;
  msg?: string;
  data?: string | object;
}
export const addFollowLabel = async (
  params: {
    labelId: string;
  },
  options?: AxiosRequestConfig<{}>
) => {
  return request.post("/user-follow-label/addFollowLabel", params, {
    ...(options || {}),
  });
};

export async function unfollowLabel(params: { labelId: string }) {
  return request.delete("/user-follow-label/unfollowLabel", {
    data: { ...params },
  });
}

export async function getOauthTokenFetch() {
  return request.get("/twitter/oauthToken", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

export const getTwitterUserFetch = async (
  params: {
    oauthToken: string;
    oauthVerifier: string;
  },
  options?: AxiosRequestConfig<{}>
) => {
  return request.post("/twitter/twitterLogin", params, {
    ...(options || {}),
  });
};

// /user-follow-label/abells - follow - status;
export async function getAllLabelStatueFetch(labelIds) {
  return request.get(`/user-follow-label/labels-follow-status`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: { ...labelIds },
  });
}

export const getPublicLabel = async (
  params: {
    labelName: string;
    pageNum: number;
    pageSize: number;
  },
  options?: AxiosRequestConfig<{}>
) => {
  return request.get("/label-share/getPublicLabelList", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params,
  });
};
