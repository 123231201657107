import { request } from "./request";

export async function getUserInfluenceRecordList(params: {
  pageNum: number;
  pageSize: number;
}) {
  return request.get("/user-ranking-settlement/influence", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: params,
  });
}
export async function getTable2RecordList(params: {
  pageNum: number;
  pageSize: number;
}) {
  return request.get("/user-ranking-settlement/popularity", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: params,
  });
}
export async function getTable2DetailRecordList(params: {
  roundId: number;
  pageNum: number;
  pageSize: number;
}) {
  return request.get("/user-ranking-settlement/popularity-data", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: params,
  });
}

export async function getTable3RecordList(params: {
  type: 1 | 2;
  pageNum: number;
  pageSize: number;
}) {
  return request.get(`/label-to-earn/getEarnDetails/${params.type}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: {
      pageNum: params.pageNum,
      pageSize: params.pageSize,
    },
  });
}
export async function getCreativeInfluenceRecordList(params: {
  pageNum: number;
  pageSize: number;
}) {
  return request.get("/user-ranking-settlement/creative", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: params,
  });
}
// /user-ranking-settlement/ammrsuy;
export async function getUserSummary() {
  return request.get("/user-ranking-settlement/earn-summary", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

export const verifyLinkUrl = async (params: { tweetLink: string }) => {
  return request.post(
    "/user-ranking-settlement/verify-receive-benefit",
    params
  );
};
export const receivePendingRewards = async (params: { verifySign: string }) => {
  return request.post(
    "/user-ranking-settlement/receive-pending-rewards",
    params
  );
};
// getMyRewardBoxFetch;
export async function getMyRewardBoxFetch() {
  return request.get("/label-to-earn/reward-box", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

// getMyRewardBoxFetch;
export async function getLabeledEarn() {
  return request.get("/label-to-earn/receive-labeled-earn", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}
