
import { Spin } from "@douyinfe/semi-ui";
import { lazy, Suspense } from "react";
import { Navigate, RouteObject } from "react-router-dom";

//主页面
const Inscribe = lazy(() => import("../pages/Inscribe"));
const Fetures = lazy(() => import("../pages/Fetures"));
const Bridge = lazy(() => import("../pages/Bridge"));
const BTCBridge = lazy(() => import("../pages/BTCBridge"));
const ShareLabel = lazy(() => import("../pages/Rank/ShareLabel"));
const LabelRanking = lazy(() => import("../pages/Rank/LabelRanking"));
const Benefits = lazy(() => import("../pages/Benefits"));
const AccontSettings = lazy(() => import("../pages/AccontSettings"));
const RewardDashboard = lazy(() => import("../pages/RewardDashboard"));
const Swap = lazy(() => import("../pages/Swap"));

const Staking = lazy(() => import("../pages/Staking"));
const FallBackElement = (
  <div
    style={{
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "80vh",
    }}
  >
    <Spin size="large" />
  </div>
);

export const HomePath = "/";
export const BridgePath = "/bridge";
export const InscribePath = "/inscribe";
export const RankingPath = "/ranking";
export const BenefitsPath = "/benefits";
export const accountPath = "/accountSettings";
//二级域名
export const rewardDashboardPath = "/reward";
export const SwapPath = "/swap";
export const ShareLabelPath = "/share/label";
export const StakingPath = "/staking";

export const BTCLineaFormPath = "/btc-linea-form"

export const SubdomainsLIst = [
  SwapPath,
  RankingPath,
  rewardDashboardPath,
  BridgePath,
  ShareLabelPath,
  StakingPath,
  BTCLineaFormPath
];

export const DontNeedHeader = [
  BTCLineaFormPath
]

const routes: RouteObject[] = [
  {
    path: HomePath,
    element: (
      <Suspense fallback={FallBackElement}>
        <Fetures />
      </Suspense>
    ),
  },
  {
    path: BridgePath,
    element: (
      <Suspense fallback={FallBackElement}>
        <Bridge />
      </Suspense>
    ),
  },
  {
    path: InscribePath,
    element: (
      <Suspense fallback={FallBackElement}>
        <Inscribe />
      </Suspense>
    ),
  },
  {
    path: RankingPath,
    element: (
      <Suspense fallback={FallBackElement}>
        <LabelRanking />
      </Suspense>
    ),
  },
  {
    path: BenefitsPath,
    element: (
      <Suspense fallback={FallBackElement}>
        <Benefits />
      </Suspense>
    ),
  },
  {
    path: accountPath,
    element: (
      <Suspense fallback={FallBackElement}>
        <AccontSettings />
      </Suspense>
    ),
  },
  {
    path: rewardDashboardPath,
    element: (
      <Suspense fallback={FallBackElement}>
        <RewardDashboard />
      </Suspense>
    ),
  },
  // {
  //   path: SwapPath,
  //   element: (
  //     <Suspense fallback={FallBackElement}>
  //       <Swap />
  //     </Suspense>
  //   ),
  // },
  {
    path: `${ShareLabelPath}/:id`,
    element: (
      <Suspense fallback={FallBackElement}>
        <ShareLabel />
      </Suspense>
    ),
  },
  {
    path: StakingPath,
    element: (
      <Suspense fallback={FallBackElement}>
      <Staking />
    </Suspense>
    )
  },
  {
    path: BTCLineaFormPath,
    element: (
      <Suspense fallback={FallBackElement}>
      <BTCBridge />
    </Suspense>
    )
  },
  {
    path: "",
    element: <Navigate to="" />,
  },
];

export default routes;
