import { Toast } from "@douyinfe/semi-ui";
import { useContext, useEffect, useRef, useState } from "react";
import { useSafeState, useUpdateEffect } from "ahooks";
import {
  getOauthTokenFetch,
  getTwitterUserFetch,
} from "../../services/LabelRank";
import { MyContext } from "../../App";
import { request } from "../../services/request";
import { isMobileDevice } from "../../utils";
const TwitterLogin = () => {
  const { refreshUser } = useContext(MyContext);
  const callbackRef = useRef<any>(null);
  const [loading, setLoading] = useSafeState<boolean>(false);
  const oauthRef = useRef<{
    oauthToken: string;
  }>({
    oauthToken: "",
  });
  const [childWindow, setChildWindow] = useState<any>(null);
  function openPopup(oauth_token: string): Window {
    const authenticationUrl =
      "https://api.twitter.com/oauth/authenticate?oauth_token=" + oauth_token;
    var w = 600;
    var h = 400;
    var left = window.screen.width / 2 - w / 2;
    var top = window.screen.height / 2 - h / 2;
    return window.open(
      authenticationUrl,
      "popup",
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        top +
        ", left=" +
        left
    ) as Window;
  }
  const getOauthToken = async (oauthVerifier) => {
    try {
      const res: any = await getTwitterUserFetch({
        ...oauthRef.current,
        oauthVerifier,
      });
      if (res?.data?.code === 200) {
        const token: any = res.data.data;
        localStorage.setItem("authToken", token);
        request.defaults.headers.Authorization = token;
        if (localStorage.getItem("authToken")) {
          refreshUser();
          callbackRef.current && callbackRef.current("fleshList");
        }
      } else {
        Toast.error(res?.data?.msg ?? "Failed to UserFetch");
      }
    } catch (e) {
      Toast.error("Failed to UserFetch");
    } finally {
      setLoading(false);
    }
  };
  const handleMessage = (event: { data: string }) => {
    //绑定账号的消息
    if (event.data && typeof event.data === "string") {
      try {
        const msg: {
          oauthVerifier: string;
        } = JSON.parse(event.data);
        if (msg.oauthVerifier) {
          //接受成功.window.window.wind
          // 在这里执行小窗口关闭后的逻辑
          getOauthToken(msg.oauthVerifier);
          childWindow.close(); //关闭子窗口
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  useUpdateEffect(() => {
    if (childWindow !== null && !childWindow) return;

    // 在父窗口中监听消息事件，接收小窗口的消息
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [childWindow]);
  const twitterLogin = async (successCalback?: () => void) => {
    if (isMobileDevice()) {
      // const res: any = await getOauthTokenFetch();
      // if (res?.data?.code === 200) {
      //   const oauthToken = res?.data?.data?.oauthToken;
      //   const authenticationUrl =
      //     "https://api.twitter.com/oauth/authenticate?oauth_token=" +
      //     oauthToken;
      //   window.location.href = authenticationUrl;
      // }
      return;
    }

    try {
      const token = localStorage.getItem("authToken");
      if (token) {
        successCalback && successCalback();
        return;
      }
      callbackRef.current = successCalback;
      setLoading(true);
      const res: any = await getOauthTokenFetch();
      if (res?.data?.code === 200) {
        const oauthToken = res?.data?.data?.oauthToken;
        oauthRef.current = { oauthToken };
        const _childWindow = openPopup(oauthToken);
        if (_childWindow === null) {
          setTimeout(() => {
            Toast.error(
              "The current new window has been blocked by the browser. Please click on the settings in the top right corner of the window and allow pop-ups"
            );
          }, 500);
        }
        setChildWindow(openPopup(oauthToken));
      } else {
        Toast.error(res?.data.msg ?? "Failed to OauthToken");
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      Toast.error("Failed to OauthToken");
    }
  };
  return {
    loading,
    twitterLogin,
  };
};

export default TwitterLogin;
