import { Modal, Spin, Toast } from "@douyinfe/semi-ui";
import { FC, useState } from "react";
import styles from "./index.module.less";
import { verifyMessage } from "@unisat/wallet-utils";
import walletInfo from "../../store/walletInfo";
import { Notification } from "@douyinfe/semi-ui";

export interface ConnectWalletModalProps {
  visible: boolean;
  onClose: () => void;
}

const ConnectWalletModal: FC<ConnectWalletModalProps> = ({
  visible,
  onClose,
}) => {
  const [isOKApp] = useState(/OKApp/i.test(navigator.userAgent));
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const connectUni = async () => {
    let unisat = (window as any).unisat;
    if (!unisat) {
      Notification.warning({
        title: "UniSat Wallet not installed",
        content:
          "Please click here to visit the product page. After installing the extension, please refresh the page.",
        duration: 3,
        className: styles.notification,

        onClick: () => {
          window.open("https://unisat.io/download");
        },
      });
      return;
    }
    try {
      setLoading(true);
      let accounts = await unisat.requestAccounts();
      let message = "BTC=" + accounts[0];
      let signature = await unisat.signMessage(message);
      let pubkey = await unisat.getPublicKey();
      let result = verifyMessage(pubkey, message, signature);
      if (result) {
        let res = await unisat.getBalance();
        // console.log(res, "balance res");
        let inscriptions = await unisat.getInscriptions(0, 1000);
        // console.log(inscriptions, "inscriptions res");
        walletInfo.setAccount(accounts[0]);
        walletInfo.setUnisatState(true);
        if (res && res.total) {
          walletInfo.setBtcBalance(res.total / 100000000);
        }
        onClose();
      } else {
        Toast.error("Verify failed");
      }
    } catch (e) {
      Toast.error("Connect failed");
    } finally {
      setLoading(false);
    }
  };
  const connectOkx = async () => {
    let okxwallet = (window as any).okxwallet;
    if (!okxwallet) {
      Notification.warning({
        title: "OKX Wallet not installed",
        className: styles.notification,
        content:
          "Please click here to visit the product page. After installing the extension, please refresh the page.",
        duration: 3,
        onClick: () => {
          window.open("https://www.okx.com/web3");
        },
      });
      return;
    }
    try {
      setLoading1(true);
      let accounts = await okxwallet.bitcoin.connect();
      let address = accounts.address;
      let message = "BTC=" + address;
      let pubkey = accounts.compressedPublicKey;
      let signature = await okxwallet.bitcoin.signMessage(message, {
        from: address,
      });
      let result = verifyMessage(pubkey, message, signature);
      if (result) {
        walletInfo.setAccount(address);
        walletInfo.setUnisatState(false);
        onClose();
      } else {
        Toast.error("Verify failed");
      }
    } catch (e) {
      Toast.error("Connect failed");
    } finally {
      setLoading1(false);
    }
  };

  return (
    <>
      <Modal
        header={null}
        footer={null}
        visible={visible}
        width={360}
        closable={true}
        closeOnEsc={true}
        onOk={() => null}
        className={styles.modal}
        onCancel={() => {
          setLoading1(false);
          setLoading(false);
          onClose();
        }}
      >
        <>
          <div className={styles.tittle}>Choose Wallet</div>
          {!isOKApp ? (
            <div className={styles.item} onClick={connectUni}>
              <img
                src="https://next-cdn.unisat.io/_/19/logo/color.svg"
                alt="logo of UniSat Wallet"
              />
              <div className={styles.name}>UniSat Wallet</div>
              {loading ? <Spin /> : ""}
            </div>
          ) : (
            ""
          )}

          <div className={styles.item} onClick={connectOkx}>
            <img
              src="https://next-cdn.unisat.io/_/19/img/wallet/okx_wallet_icon.svg"
              alt="logo of OKX Wallet"
            />
            <div className={styles.name}>OKX Wallet</div>
            {loading1 ? <Spin /> : ""}
          </div>
        </>
      </Modal>
    </>
  );
};

export default ConnectWalletModal;
