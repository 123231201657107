import axios from "axios";
import NProgress from "nprogress";

//配置进度条参数
NProgress.configure({
  showSpinner: false,
  minimum: 0.2,
  speed: 1000,
});

//防止多次请求进度条重复加载
let loadingNum = 0;
function startLoading() {
  if (loadingNum === 0) {
    NProgress.start();
  }
  loadingNum++;
}
function endLoading() {
  loadingNum--;
  if (loadingNum <= 0) {
    NProgress.done();
  }
}
const language = navigator.language === "zh-CN" ? "zh_CN" : "en_US";
export const request = axios.create({
  baseURL: "/api",
  timeout: 60000 * 5,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": "en_US",
    Authorization: localStorage.getItem("authToken"),
    Time_Zone: "UTC+" + (0 - new Date().getTimezoneOffset() / 60),
  },
});
request.interceptors.request.use(
  (config) => {
    // 加载进度条
    startLoading();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
