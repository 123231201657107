const enUS = {
    "再次操作": "操作失败，请稍后再试。",
    'Remaining Time': 'Remaining Time',
    "Exit the success": "Exit the success",
    'Docs': 'Docs',
    'Download': 'Download',
    'English': 'English',
    '热门榜单': 'Hot Ranking',
    '标签创作榜': 'Label Creation Ranking',
    '标签传播榜': 'Label Propagation Ranking',
    '影响力KOL榜': 'Influencer KOL List',
    'Account': 'Account',
    'Introduction': 'Introduction',
    'Followers': 'Followers',
    'Share Count': 'Share Count',
    'Share CountDes': 'The total number of shares by this account.',
    'Total Clicks': 'Total Clicks',
    'Total ClicksDes': 'The total number of clicks on links shared by this account.',
    'Current Clicks': 'Current Clicks',
    'Current ClicksDes': 'The total number of clicks on links shared by this account this period.',
    'Bonus Reward': 'Bonus Reward',
    'Bonus RewardDes': 'The current additional computational power bonus.',
    "Follow": "Follow",
    "Label": "Label",
    "Clicks": "Clicks",
    'Share': 'Share',
    'No data': 'No data',
    "Sign up with Twitter": "Sign up with Twitter",
    "About": "About",
    "Medium": "Medium",
    "Launch": "Launch",
    //tab3
    'KOL': 'KOL',
    'KOL Introduction': 'KOL Introduction',
    'Tagged': 'Tagged',

    'Influence': 'Influence',
    'TaggedDes': 'The number of times this account has been tagged',
    'InfluenceDes': 'The total number of followers for tags that have tagged this KOL',

    //tab1
    '总关注': 'Followers',
    'Creator': 'Creator',
    'CreatorDes': 'The creator of this Label.',
    'Members': 'Members',
    'MembersDes': 'How many accounts is this tag labeled.',
    'Influence1': 'Influence',
    'Influence1Des': 'The total number of followers of accounts tagged with this label.',
    'Account1': 'Account',

    'Following': 'Following',
    'Followers1': 'Followers',
    "Increase": "Increase",
    'Recent Activity': 'Recent Activity',
    "IncreaseDes": "The number of new followers this label gained during the current ranking period.",
    "Log out": "Log out",
    "Follow label successfully": "Follow label successfully",
    "Unfollow label successfully": "Unfollow label successfully",
    "cancelFollow": "Unfollow",
    'FollowLabelDex': 'You will be lable to track the latest tweets related to that lable in Summafeeds after following.',
    'ShareLabelDes': 'Click, propagate high-quality community labels, participate in mining, and earn rewards!',
    'CreationDes': 'Track the increase in followers for this label during the ongoing ranking period.',
    "PropagationDes": 'Tracking top label-sharers by clicks.',
    'KOLDes': 'Tracking most followed KOLs tagged with labels.',

    //modal
    "Followed successfully!": "Followed successfully!",
    'Check and share now': 'Check and share now',
    'participate in mining, and get rewards!': 'participate in mining, and get rewards!',
    'Mint Now': 'Mint Now',
    'Later': 'Later',

    //modal2
    'copyALl': 'Copy All',
    'copyLink': 'Copy Link',
    'sharemodalSharing': 'Sharing',
    'sharemodalDes1': 'Open the shared label link in your browser and participate in label mint.',
    'Change It': 'Change It',
    'sharemodalText1': 'Community news! Waiting for you to mint !',
    'sharemodalText2': "Engage in label mint to help more users discover and follow KOL's labels.",
    'sharemodalText3': 'Join the propagation mining initiative!',
    'sharemodalText4': 'Start by copying and spreading top community tags now to participate',
    'sharemodalText5': 'in mining and earn rewards!',
    'sharemodalText6': 'Explore community hotspots!',
    'sharemodalText7': 'Share tags with more people, participate in mining, and share rewards!',
    '收录人数': 'Members',
    //share
    '成员': 'Members',
    'Followed': 'Followed',
    '粉丝': 'Followers',
    '正在关注': 'Following',
    '最后更新时间': 'Last updated time',
    '推特用户': 'Twitter Account',

    'earnSRVSCoin': 'Share To Earn',
    'Search': 'Please enter keywords',
    'Disconnect': 'Disconnect',
    'Account Settings': 'Account Settings',
    "No more data": "No more data",
    'shwShareText': 'No S.A.R.V.I.S. Inscription yet? Get it now or join the community for a redemption code and explore more Sumup features!',
    'Get Now': 'Get Now',
    'Join Now': 'Join Now',
    'Loading': 'Loading',
    'Ranking': 'Ranking',
    'Exchange': 'Exchange',
    'SRVS Staking': 'SRVS Staking',
    'Reward Dashboard': 'Reward Dashboard',
    //rewardDashboard
    'rewardDes': 'Members Enjoy Additional Benefits and Rewards',
    "Creative Rewards": "Creative Rewards",
    'Share Rewards': 'Share Rewards',
    'Label to Earn': 'Label to Earn',
    'Labeled to Earn': 'Labeled to Earn',
    'Influence Rewards': 'Influence Rewards',
    'Rewards List': 'Rewards List',
    "Reward": "Reward",
    'Amount': 'Amount',
    'My Labels': 'My Labels',
    'My Spread': 'My Spread',
    'My Influence': 'My Influence',
    'Click': 'Click',
    'Copied to clipboard': 'Copied to clipboard',
    'Labels': 'Labels',
    'Pending Rewards': 'Pending Rewards',
    'step1Des': 'Please click this button below and tweet a verification message on Twitter,keep your tweets visible for at least three days.',
    'step2Des': 'On your tweet, find the share button. Copy link and paste it here. Click the button to verify your account.',
    'step2Error': 'Verification failed !',
    'Claim': 'Claim',
    'Data': 'Data',
    'Claim successfully': 'Claim successfully',
    "Claim failed": "Claim failed",
    'rewardMaskDes': 'Sign in to your account now, check, and claim your rewards!',
    "Swap": "Swap",
    'Coming soon': 'Coming soon!',
    "public Labels": "Public Labels",
    "Withdraw": "Withdraw",
    "Balance": "Balance",
    "Swap Rate": "Swap Rate",
    "History": "History",
    "MySwap": "My Swap",
    //myHistory
    "Participants": "Participants",
    "Added": "Added",
    "HistorySwap": 'Swap',
    "Rate": "Rate",
    "Date": "Date",
    "swapErroAmount0": 'Please enter the balance',
    "swapErroAmountMax": "Insufficient balance",
    "Calculating": "Calculating",
    "Live": "Live",
    "WithdrawSuccess": "Withdraw successfully",
    "AddSuccess": "Add successfully",
    "Next": "Next",
    "Start Later": 'Start Later',
    "swapAdd": 'Add',
    "swapWithdrawError": 'Exceeds withdrawable balance',
    "Confirm": "Confirm",
    "Cancel": "Cancel",










}
export default enUS;