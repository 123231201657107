import dayjs, { Dayjs } from 'dayjs';

export * from './address';

export function shortString(str?: string): string {
    if (str) {
        if (str.length <= 10) {
            return str;
        }
        return `${str.slice(0, 5)}...${str.slice(str.length - 5, str.length)}`;
    }
    return '';
}

export const copyToClip = (content, callback) => {
  let aux = document.createElement("input");
  aux.setAttribute("value", content);
  document.body.appendChild(aux);
  aux.select();
  document.execCommand("copy");
  document.body.removeChild(aux);
  if (callback) {
    callback();
  }
};

export function isValidPositiveInteger(input: string) {
  const regex = /^[1-9]\d*$/;
  return regex.test(input);
}

export const day = (d: string | Dayjs | Date) => {
  return dayjs.utc(d).local();
};

/** 地址缩写 */
export const onAddressAbbreviation = (address: string) => {
  return (
    address.substring(0, 5) + "..." + address.substring(address.length - 4)
  );
};
export const mintTime = () => {
  return day("2024-01-01 12:00")
}
export const rSRVS='rSRVS'

export const customNumbro = (num: number): string => {
  const units = ["", "K", "M", "B", "T"];
  let unitIndex = 0;
  while (num >= 1000 && unitIndex < units.length - 1) {
    num /= 1000;
    unitIndex++;
  }
  const multiplier = Math.pow(10, 1);
  const truncatedNumber = Math.floor(num * multiplier) / multiplier;
  return truncatedNumber + units[unitIndex];
};
export const buyCoinUrl = "https://unisat.io/brc20/srvs";
export const TGUrl = "https://t.me/sarvisai";
export const sumupUrl = "https://sumup.cloud";

export const isEmpty = obj => [Object, Array].includes((obj || {}).constructor) && !Object.entries((obj || {})).length;

export const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const isOKX = () => {
  return /OKApp/i.test(navigator.userAgent);
}