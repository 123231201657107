import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import routesConfig, { DontNeedHeader } from "./routes";
import "./App.less";
import { createContext, useEffect, useLayoutEffect, useState } from "react";
import Autofit from "autofit.js";
import { IntlProvider } from "react-intl";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import { useRequest } from "ahooks";
import { userCurretnFetch } from "./services/LabelRank";
import myEn_US from "./locale/en-US";
import myZh_CN from "./locale/zh-CN";
import Header from "./Layout/Header";
import GiftBox from "./component/GiftBox";
import { request } from "./services/request";
dayjs.extend(relativeTime);
dayjs.extend(utc);
const myLocal = {
  "zh-cn": myZh_CN,
  "en-us": myEn_US,
};
export const defaultUser = {
  id: -99,
  email: "",
  twitterAccount: "",
  twitterUserName: "",
  userName: "",
  vipComboState: 0,
  maxWriteOffCodeNum: 0,
  useWriteOffCodeNum: 0,
  isBindTelegram: false,
  allowNum: 200,
  address: "",
  amount: 0,
  curPrice: 0,
};
export const MyContext: React.Context<{
  userInfo: any;
  refreshUser: () => void;
  setUserInfoState: (userInfo: any) => void;
}> = createContext({
  userInfo: defaultUser,
  refreshUser: () => {},
  setUserInfoState: () => {},
});
function App() {
  const routes = useRoutes(routesConfig);
  const location = useLocation();

  const navigate = useNavigate();
  const [locale, setLocale] = useState<"zh-cn" | "en-us">("en-us");
  const { run: refreshUser } = useRequest(userCurretnFetch, {
    manual: true,
    onSuccess: (res) => {
      if (res?.data?.code !== 200) {
        localStorage.removeItem("authToken");
      } else {
        setUserInfoState(res?.data?.data ?? {});
      }
    },
  });
  const [userInfoState, setUserInfoState] = useState({});
  useLayoutEffect(() => {
    const mobileRegex =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    try {
      if (mobileRegex.test(navigator.userAgent)) {
        Autofit.init({
          dh: 1080,
          dw: 1920,
          el: "#root",
          resize: true,
        });
      }
    } catch (e) {}
    return () => {
      if (mobileRegex.test(navigator.userAgent)) {
        Autofit.off();
      }
    };
  }, []);

  useLayoutEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const queryAuToken = query.get("authToken");
    if (queryAuToken) {
      localStorage.removeItem("authToken");
      localStorage.setItem("authToken", queryAuToken);
      request.defaults.headers.Authorization = queryAuToken;
      const { pathname } = location;
      navigate(pathname);
    }

    const token = localStorage.getItem("authToken");
    if (token) {
      refreshUser();
    }
  }, []);
  return (
    <IntlProvider locale={locale} messages={myLocal[locale]}>
      <MyContext.Provider
        value={{
          userInfo: userInfoState,
          refreshUser,
          setUserInfoState,
        }}
      >
        <div className="app">
          {!DontNeedHeader.includes(location.pathname) && <Header />}
          {routes}
          {!DontNeedHeader.includes(location.pathname) && <GiftBox locale={locale} /> }
        </div>
      </MyContext.Provider>
    </IntlProvider>
  );
}

export default App;
