import styles from "./index.module.less";
import giftGif from "./gift.gif";
import { IconChevronDownStroked } from "@douyinfe/semi-icons";
import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import { Toast } from "@douyinfe/semi-ui";
import { getMyRewardBoxFetch } from "../../services/reward";

const GiftBox: FC<{
  locale: string;
}> = ({ locale }) => {
  const [data, setData] = useState({} as any);
  const [show, setShow] = useState(false);
  const list = data?.howToEarnList ?? [];
  const onshow = () => {
    if (!show) {
      getList();
    }
    setShow((show) => !show);
  };
  const getList = async () => {
    const res = await getMyRewardBoxFetch();

    setData(res?.data?.data ?? {});
  };

  return (
    <div
      className={classNames(styles.box, {
        rightBottomGiftBox: true,
      })}
    >
      <div className={styles.caotain} style={{ display: show ? "" : "none" }}>
        <div className={styles.listBox}>
          <div className={styles.top}>
            <div className={styles.left}>
              <img src={giftGif} className={styles.topGif} alt="" />
              <div className={styles.topText}>
                {locale === "zh-cn" ? "开始获取奖励" : "Start Earning Now"}
              </div>
            </div>
            <IconChevronDownStroked onClick={onshow} />
          </div>

          {list.map((item, index) => {
            if (index === list.length - 1) {
              return (
                <div
                  className={styles.toEarn}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (!item.link) {
                      Toast.info(
                        locale === "zh-cn" ? "即将上线" : "Coming soon"
                      );
                      return;
                    }
                    window.open(item.link);
                  }}
                  key={index}
                >
                  {locale === "zh-cn" ? "玩法说明" : "How to Earn"}
                </div>
              );
            }

            const isNOJifen = item.assetsKey !== "Point";
            return (
              <div
                className={styles.item}
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (!item.link) {
                    Toast.info(locale === "zh-cn" ? "即将上线" : "Coming soon");
                    return;
                  }
                  window.open(item.link);
                }}
              >
                <div className={styles.left}>
                  <img
                    src={item.assetsLogo}
                    style={{
                      width: isNOJifen ? 28 : 24,
                      height: isNOJifen ? 26 : 24,
                    }}
                    alt=""
                  />
                  <div className={styles.name}>{item.categoryName}</div>
                </div>
                <div className={styles.num}>{item.expectEarn}+</div>
              </div>
            );
          })}
        </div>
      </div>

      <img
        src={giftGif}
        className={classNames(styles.gif, {})}
        alt=""
        onClick={onshow}
      />
    </div>
  );
};
export default GiftBox;
