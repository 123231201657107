import { useIntl } from "react-intl";

export const useFormat = () => {
  const intl = useIntl();
  const locale = intl.locale;
  const format = (text: string, params?: Record<string, any>) => {
    if (!text) return text;
    return intl.formatMessage(
      {
        id: text,
      },
      params
    );
  };
  return { format, locale };
};
